 





// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import { doc, getDoc } from "firebase/firestore";
// import { db, auth } from "./firebaseConfig";
// import Chat from './Chat';
// import "./PostDetails.css";

// function PostDetails() {
//   const { id } = useParams();
//   const [post, setPost] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedImage, setSelectedImage] = useState(null);

//   // Updated useEffect with authentication check
//   useEffect(() => {
//     if (!auth.currentUser) {
//       console.error("User is not authenticated");
//       return;
//     }
    
//     const fetchPost = async () => {
//       try {
//         const postDoc = await getDoc(doc(db, "posts", id));
//         if (postDoc.exists()) {
//           setPost({ id: postDoc.id, ...postDoc.data() });
//         } else {
//           console.error("Post not found!");
//         }
//       } catch (error) {
//         console.error("Error fetching post:", error);
//       }
//     };
//     fetchPost();
//   }, [id]);

//   if (!post) return <div>Post not found!</div>;

//   const openModal = (imageUrl) => {
//     setSelectedImage(imageUrl);
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     setSelectedImage(null);
//   };

//   return (
//     <div className="post-details">
//       <h1>{post.title}</h1>
//       <p><span>Price : </span>{post.price}</p>
//       <p><span>Contact : </span>{post.contact}</p>
//       <p><span>Message : </span>{post.content}</p>
//       <div className="post-images">
//         {post.imageUrls.map((url, index) => (
//           <img
//             key={index}
//             src={url}
//             alt={`Post Image ${index + 1}`}
//             onClick={() => openModal(url)}
//             className="clickable-image"
//           />
//         ))}
//       </div>
//       <Chat currentUserId={auth.currentUser.uid} postOwnerId={post.userId} />
//       <p>Posted by: {post.username}</p>

//       {/* Modal */}
//       {isModalOpen && (
//         <div className="modal" onClick={closeModal}>
//           <div className="modal-content" onClick={(e) => e.stopPropagation()}>
//             <span className="close-button" onClick={closeModal}>
//               &times;
//             </span>
//             <img src={selectedImage} alt="Selected" className="modal-image" />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default PostDetails;














// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import { doc, getDoc } from "firebase/firestore";
// import { db, auth } from "./firebaseConfig";
// import Chat from './Chat';
// import "./PostDetails.css";

// function PostDetails() {
//   const { id } = useParams();
//   const [post, setPost] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedImage, setSelectedImage] = useState(null);

//   useEffect(() => {
//     if (!auth.currentUser) {
//       console.error("User is not authenticated");
//       return;
//     }
    
//     const fetchPost = async () => {
//       try {
//         const postDoc = await getDoc(doc(db, "posts", id));
//         if (postDoc.exists()) {
//           setPost({ id: postDoc.id, ...postDoc.data() });
//         } else {
//           console.error("Post not found!");
//         }
//       } catch (error) {
//         console.error("Error fetching post:", error);
//       }
//     };
//     fetchPost();
//   }, [id]);

//   if (!post) return <div>Post not found!</div>;

//   const openModal = (imageUrl) => {
//     setSelectedImage(imageUrl);
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     setSelectedImage(null);
//   };

//   const handleSuccessAlert = () => {
//     alert('Message sent successfully!');
//   };

//   return (
//     <div className="post-details">
//       <h1>{post.title}</h1>
//       <p><span>Price : </span>{post.price}</p>
//       <p><span>Contact : </span>{post.contact}</p>
//       <p><span>Message : </span>{post.content}</p>
//       <div className="post-images">
//         {post.imageUrls.map((url, index) => (
//           <img
//             key={index}
//             src={url}
//             alt={`Post Image ${index + 1}`}
//             onClick={() => openModal(url)}
//             className="clickable-image"
//           />
//         ))}
//       </div>
//       <Chat 
//         currentUserId={auth.currentUser.uid} 
//         postOwnerId={post.username}
//         onMessageSent={handleSuccessAlert} // Pass the alert function
//       />
      
//       <p>Posted by: {post.username}</p>
      
//       {/* <p>User ID: {user.uid}</p> */}
//       <p>Post ID: {post.id}</p>
//       <p>Post Owner ID: {post.userId}</p> 

//       {isModalOpen && (
//         <div className="modal" onClick={closeModal}>
//           <div className="modal-content" onClick={(e) => e.stopPropagation()}>
//             <span className="close-button" onClick={closeModal}>
//               &times;
//             </span>
//             <img src={selectedImage} alt="Selected" className="modal-image" />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default PostDetails;












import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "./firebaseConfig";
import Chat from './Chat';
import "./PostDetails.css";

function PostDetails() {
  const { id } = useParams();
  const location = useLocation();
  const [post, setPost] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);



  useEffect(() => {
    if (!auth.currentUser) {
      console.error("User is not authenticated");
      return;
    }
    
    const fetchPost = async () => {
      try {
        const postDoc = await getDoc(doc(db, "posts", id));
        if (postDoc.exists()) {
          setPost({ id: postDoc.id, ...postDoc.data() });
        } else {
          console.error("Post not found!");
        }
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };
    fetchPost();
  }, [id]);

  if (!post) return <div>Post not found!</div>;


   // Default values if fields are not filled
   const title = post?.title || '';
   const year = post?.year || '';
   const titlename = post?.titlename || '';
   const collegename = post?.collegename || '';

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const handleSuccessAlert = () => {
    alert('Message sent successfully!');
  };

  return (
    <div className="post-details">
      <h1>{`${title} ${year} ${titlename} ${collegename}`.trim()}</h1>
      {/* <h1>{post.title+" "+post.year+" "+ post.titlename+" "+post.collegename}</h1> */}
      <p><span>Price : </span>{post.price || 'No price available'}</p>
      <p><span>Contact : </span>{post.contact || 'No contact provided'}</p>
      <p><span>Message : </span>{post.content || 'No message available'}</p>
      <div className="post-images">
        {post.imageUrls.map((url, index) => (
          <img
            key={index}
            src={url}
            alt={`Post Image ${index + 1}`}
            onClick={() => openModal(url)}
            className="clickable-image"
          />
        ))}
      </div>
      {/* <Chat 
        currentUserId={auth.currentUser.uid} 
        postOwnerId={location.state.postOwnerId || post.userId}  // Pass postOwnerId to Chat component
        onMessageSent={handleSuccessAlert} 
      /> */}
      <p>Posted by: {post.name}</p>
      {/* <p>Posted by: {post.username}</p>
      <p>Post ID: {post.id}</p>
      <p>Post Owner ID: {post.userId}</p> */}

      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-button" onClick={closeModal}>
              &times;
            </span>
            <img src={selectedImage} alt="Selected" className="modal-image" />
          </div>
        </div>
      )}
    </div>
  );
}

export default PostDetails;
