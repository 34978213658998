import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "./firebaseConfig";
import "./Signin.css";
import image1 from "./Image/Icons/011-bookssky-removebg-preview.png";
import logo from "./Image/GreenSwapLogo.png";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { BsCurrencyDollar } from "react-icons/bs";
import { TbArrowLoopRight } from "react-icons/tb";
import { BsFillBagCheckFill } from "react-icons/bs"; // Bag check icon
import { AiFillEye } from "react-icons/ai"; // Eye icon
import { FaBullseye } from "react-icons/fa"; // Bullseye icon
import image2 from "./Image/Bookexchange.png";
import image3 from "./Image/Moneyexchangeo.png";
import image4 from "./Image/Moneyexchange3.png";
import { TbArrowsExchange } from "react-icons/tb"; // Reversible icon
import phonedisplay from "./Image/Phonedispaly1.png";
import laptopdisplay from "./Image/laptopdispaly1.png";
import twohand from "./Image/twohand.png";
// import TextReveal from "./components/magicui/text-reveal";
// import TextReveal from "../components/magicui/text-reveal";
import TextReveal from "./components/Titiletext";
import exchange from "./Image/exchange.png";
import books from "./Image/books.png";
import msmelogo from "./Image/msme-logo.png";
import About from "./About";
import AOS from "aos";
import "aos/dist/aos.css";

function Signin() {
  const [authError, setAuthError] = useState(null);
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const signIn = async () => {
    setAuthError(null);
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate("/"); // Navigate to main page after sign-in
    } catch (error) {
      if (error.code === "auth/popup-closed-by-user") {
        setAuthError(
          "Authentication popup was closed before completing the sign-in process."
        );
      } else {
        setAuthError(
          "An error occurred during the sign-in process. Please try again."
        );
      }
      console.error("Sign-in error:", error);
    }
  };

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="sign-in-container">
     
      <h1 data-aos="zoom-in-down">
        <img className="logo" src={logo} alt="image1" />
        TurnOn
        <span>
          <BsCurrencyDollar
            style={{
              color: "#daa520",
              fontSize: "1em",
              marginbottom: "80px",
            }}
          />
        </span>
        ell.Com
      </h1>

      <div className="sign-in-image">
        <img
          className="card"
          src={phonedisplay}
          alt="phonedisplay"
          data-aos="zoom-out-up"
        />
        <img
          className="card"
          src={image1}
          alt="image1"
          data-aos="zoom-out-up"
        />
        {/* <img className="card" src={twohand} alt="image1" /> */}
        <img
          className="card"
          src={laptopdisplay}
          alt="laptopdisplay"
          data-aos="zoom-out-up"
        />
      </div>

      {/* <div className="animations" >kundan</div>
      
      <div className="animations" data-aos="fade-up">kundan</div> */}

      <div>
        {authError && <div className="error-message">{authError}</div>}

        <button
          className="auth-button"
          onClick={signIn}
          data-aos="zoom-in-down"
        >
          Sign In with Google
        </button>
      </div>

      <br />
      <h1 data-aos="zoom-out-up">
        Welcome to the best book-selling platform{" "}
        <img src={exchange} alt="Freepik" className="tag-line" />{" "}
      </h1>
      <br />

      <div>
        <h1 className="section-heading work">
          <span className="icon">
            <BsFillBagCheckFill />
          </span>
          Work
        </h1>
        <div className="card-container">
          <div className="card1">
            <img
              src={image2}
              alt="Image 2"
              className="card-image"
              data-aos="fade-up"
            />
            <TbArrowsExchange className="reversible-icon" />
            <img
              src={image4}
              alt="Image 4"
              className="card-image"
              data-aos="fade-up"
            />
          </div>
        </div>
        <p className="section-paragraph" data-aos="fade-up">
          On TurnOnSell.com, senior students can list their books along with the
          details and contact information. Junior students from the same college
          can search for books using filters like degree, year, book name, and
          college name. They can easily find the contact details on the post and
          connect with the seller. This allows junior and senior students to
          meet at their college and exchange books for money directly.
        </p>
      </div>

      <About />

      <social>
        <div class="social-container">
          <h1 data-aos="flip-up">connect with us</h1>
          <ul class="social-icons">
            <li data-aos="flip-left">
              <a href="https://www.instagram.com/turnonsell/">
                <i class="fa fa-instagram"></i>
              </a>
            </li>
            <li data-aos="flip-left">
              <a href="https://x.com/TurnOnSell">
                <i class="fa fa-twitter"></i>
              </a>
            </li>
            {/* <li><a href="#"><i class="fa fa-linkedin"></i></a></li> */}
            <li data-aos="flip-right">
              <a
                href="https://wa.me/7304868112"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fa fa-whatsapp"></i>
              </a>
            </li>
            <li data-aos="flip-right">
              <a href="mailto:turnonsell@gmail.com">
                {" "}
                <i class="fa fa-envelope" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </div>
      </social>

      <div className="msme">
          <img src={msmelogo} alt="msme" />
          <p5>Registration Number: UDYAM-MH-18-0374222</p5>
      </div>

      <footer>
        Copyright © {currentYear}, TurnOnSell.com All Rights Reserved
      </footer>
    </div>
  );
}

export default Signin;
