


//-----------------------------------------------------------------------------------------------------------------------------------


// import React from 'react';
// import './Post.css';

// function Post({ post, user, onDelete }) {
//   const imageUrls = post.imageUrls || [];

//   return (
//     <div className="post">
//       <h3>{post.title}</h3>
//       <p>{post.content}</p>
//       <div className="post-images">
//         {imageUrls.map((url, index) => (
//           <img key={index} src={url} alt={`Post Image ${index + 1}`} />
//         ))}
//       </div>
//       {user && post.userId === user.uid && (
//         <button className="delete-button" onClick={onDelete}>Delete</button>
//       )}
//       <p>Posted by: {post.username}</p>
//     </div>
//   );
// }

// export default Post;










// import React from 'react';
// import './Post.css';

// function Post({ post, user, onDelete }) {
//   const imageUrls = post.imageUrls || [];

//   return (
//     <div className="post">
//       <h3>{post.title}</h3>
//       <p>{post.content}</p>
//       <div className="post-images">
//         {imageUrls.map((url, index) => (
//           <img key={index} src={url} alt={`Post Image ${index + 1}`} />
//         ))}
//       </div>
//       {user && post.userId === user.uid && (
//         <button className="delete-button" onClick={onDelete}>Delete</button>
//       )}
//       <p>Posted by: {post.username}</p>
//     </div>
//   );
// }

// export default Post;





//----------------------------------------------------------------------------------------------------------

          // NEW CODE From different account
          //below
//----------------------------------------------------------------------------------------------------------



// import React from 'react';
// import './Post.css';

// function Post({ post, user, onDelete }) {
//   const imageUrls = post.imageUrls || [];

//   return (
//     <div className="post">
//       <h3>{post.title +" "+ post.titlename}</h3>
//       <p>Price:{post.price}</p>
//       {/* <p>Contact:{post.contact}</p> */}
//       {/* <p>{post.content}</p> */}
//       <div className="post-images">
//         {imageUrls.map((url, index) => (
//           <img key={index} src={url} alt={`Post Image ${index + 1}`} />
//         ))}
//       </div>
//       {user && post.userId === user.uid && (
//         <button className="delete-button" onClick={onDelete}>Delete</button>
//       )}
//       {/* <p>Posted by: {post.name}</p> */}
//       {/* <p>Posted by: {post.username}</p> */}
//     </div>
//   );
// }

// export default Post;



// ---------------------------------------------------------above code is live so much important ------------------------------------



import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Post.css';

function Post({ post, user, onDelete }) {
  const navigate = useNavigate();  // Updated to use useNavigate
  const imageUrls = post.imageUrls || [];

  // const handleClick = () => {        //important main he
  //   navigate(`/post/${post.id}`);  // Updated to use navigate
  // };


  // const handleClick = () => {
  //   // Pass the postOwnerId along with the navigation
  //   navigate(`/chat/${post.userId}/${post.id}`);
  // };


   // Ensure these fields are not undefined by defaulting to an empty string if not provided
   const title = post.title || '';
   const year = post.year || '';
   const titlename = post.titlename || '';
   const collegename = post.collegename || '';

  const handleClick = () => {
    navigate(`/post/${post.id}`, { state: { postOwnerId: post.userId } });
  };


  return (
    <div className="post" onClick={handleClick}>
      {/* <h3>{post.title}</h3> */}
      {/* <h3>{post.title + " " +post.year+" "+ post.titlename+" "+post.collegename}</h3> */}
      <h3>{`${title} ${year} ${titlename} ${collegename}`.trim()}</h3>
      <p>Price: {post.price}</p>
      {/* <p>Contact: {post.contact}</p> */}
      {/* <p>{post.content}</p> */}
      <div className="post-images">
        {imageUrls.map((url, index) => (
          <img key={index} src={url} alt={`Post Image ${index + 1}`} />
        ))}
      </div>
      {user && post.userId === user.uid && (
        <button className="delete-button" onClick={onDelete}>Delete</button>
      )}
      <p>Posted by: {post.name}</p>
      {/* <p>Posted by: {post.username}</p>
      <p>User ID: {user.uid}</p>
      <p>Post ID: {post.id}</p>
      <p>Post Owner ID: {post.userId}</p>  */}
    </div>
  );
}

export default Post;


