// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { collection, query, where, onSnapshot } from 'firebase/firestore';
// import { db, auth } from './firebaseConfig';
// import './ChatPage.css'; // Create and style this CSS file

// function ChatPage() {
//   const { chatId } = useParams();
//   const [messages, setMessages] = useState([]);

//   useEffect(() => {
//     const q = query(collection(db, 'chats', chatId, 'messages'), where('timestamp', '!=', null));

//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const msgs = snapshot.docs.map(doc => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       setMessages(msgs);
//     });

//     return () => unsubscribe();
//   }, [chatId]);

//   return (
//     <div className="chat-page">
//       {messages.map((msg) => (
//         <div key={msg.id} className={`chat-message ${msg.senderId === auth.currentUser.uid ? 'sent' : 'received'}`}>
//           {msg.text}
//         </div>
//       ))}
//     </div>
//   );
// }

// export default ChatPage;










// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';
// import { db, auth } from './firebaseConfig';
// import './ChatPage.css'; // Create and style this CSS file

// function ChatPage() {
//   const { chatId } = useParams();
//   const [messages, setMessages] = useState([]);

//   useEffect(() => {
//     const q = query(
//       collection(db, 'chats', chatId, 'messages'),
//       orderBy('timestamp', 'asc')
//     );

//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const msgs = snapshot.docs.map(doc => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       setMessages(msgs);
//     });

//     return () => unsubscribe();
//   }, [chatId]);

//   return (
//     <div className="chat-page">
//       {messages.map((msg) => (
//         <div key={msg.id} className={`chat-message ${msg.senderId === auth.currentUser.uid ? 'sent' : 'received'}`}>
//           {msg.text}
//         </div>
//       ))}
//     </div>
//   );
// }

// export default ChatPage;










// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';
// import { db, auth } from './firebaseConfig';
// import './ChatPage.css'; // Create and style this CSS file

// function ChatPage() {
//   const { chatId } = useParams();
//   const [messages, setMessages] = useState([]);

//   useEffect(() => {
//     const q = query(
//       collection(db, 'chats', chatId, 'messages'),
//       orderBy('timestamp', 'asc')
//     );

//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const msgs = snapshot.docs.map(doc => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       setMessages(msgs);
//     });

//     return () => unsubscribe();
//   }, [chatId]);

//   return (
//     <div className="chat-page">
//       {messages.map((msg) => (
//         <div key={msg.id} className={`chat-message ${msg.senderId === auth.currentUser.uid ? 'sent' : 'received'}`}>
//           {msg.text}
//         </div>
//       ))}
//     </div>
//   );
// }

// export default ChatPage;






// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';
// import { db, auth } from './firebaseConfig';
// import './ChatPage.css';

// function ChatPage() {
//   const { chatId } = useParams();
//   const [messages, setMessages] = useState([]);

//   useEffect(() => {
//     if (!auth.currentUser) return;

//     const messagesRef = collection(db, 'chats', chatId, 'messages');
//     const q = query(messagesRef, orderBy('timestamp', 'asc'));

//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const msgs = snapshot.docs.map(doc => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       setMessages(msgs);
//     });

//     return () => unsubscribe();
//   }, [chatId]);

//   return (
//     <div className="chat-page">
//       {messages.map((msg) => (
//         <div key={msg.id} className={`chat-message ${msg.senderId === auth.currentUser.uid ? 'sent' : 'received'}`}>
//           {msg.text}
//         </div>
//       ))}
//     </div>
//   );
// }

// export default ChatPage;







// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';
// import { db, auth } from './firebaseConfig';
// import './ChatPage.css';

// function ChatPage() {
//   const { chatId } = useParams();  
//   const [messages, setMessages] = useState([]);

//   useEffect(() => {
//     if (!auth.currentUser) {
//       console.error("User is not authenticated");
//       return;
//     }

    
//     console.log("Chat ID:", chatId);

//     const messagesRef = collection(db, 'chats', chatId, 'messages');
//     const q = query(messagesRef, orderBy('timestamp', 'asc'));

//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const msgs = snapshot.docs.map(doc => ({
//         id: doc.id,
//         ...doc.data()
//       }));

      
//       console.log("Fetched messages:", msgs);

//       setMessages(msgs);
//     });

//     return () => unsubscribe();
//   }, [chatId]);

//   return (
//     <div className="chat-page">
//       {messages.map((msg) => (
//         <div key={msg.id} className={`chat-message ${msg.senderId === auth.currentUser.uid ? 'sent' : 'received'}`}>
//           {msg.text}
//         </div>
//       ))}
//     </div>
//   );
// }

// export default ChatPage;








// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';
// import { db, auth } from './firebaseConfig';
// import './ChatPage.css';

// function ChatPage() {
//   const { chatId } = useParams();
//   const [messages, setMessages] = useState([]);

//   useEffect(() => {
//     if (!auth.currentUser) {
//       console.error("User is not authenticated");
//       return;
//     }

//     const messagesRef = collection(db, 'chats', chatId, 'messages');
//     const q = query(messagesRef, orderBy('timestamp', 'asc'));

//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const msgs = snapshot.docs.map(doc => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       setMessages(msgs);
//     });

//     return () => unsubscribe();
//   }, [chatId]);

//   return (
//     <div className="chat-page">
//       {messages.map((msg) => (
//         <div key={msg.id} className={`chat-message ${msg.senderId === auth.currentUser.uid ? 'sent' : 'received'}`}>
//           {msg.text}
//           <span className="timestamp">{new Date(msg.timestamp?.seconds * 1000).toLocaleString()}</span>
//         </div>
//       ))}
//     </div>
//   );
// }

// export default ChatPage;















// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';
// import { db, auth } from './firebaseConfig';
// import './ChatPage.css';

// function ChatPage() {
//   const { chatId, postOwner } = useParams(); // Adjust to retrieve both chatId and postOwner from the URL
//   const [messages, setMessages] = useState([]);

//   useEffect(() => {
//     if (!auth.currentUser) {
//       console.error("User is not authenticated");
//       return;
//     }

//     const messagesRef = collection(db, 'chats', chatId, 'messages');
//     const q = query(messagesRef, orderBy('timestamp', 'asc'));

//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const msgs = snapshot.docs.map(doc => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       setMessages(msgs);
//     });

//     return () => unsubscribe();
//   }, [chatId]);

//   return (
//     <div className="chat-page">
//       <h2>Chat with {postOwner}</h2>
//       {messages.map((msg) => (
//         <div key={msg.id} className={`chat-message ${msg.senderId === auth.currentUser.uid ? 'sent' : 'received'}`}>
//           {msg.text}
//           <span className="timestamp">{new Date(msg.timestamp?.seconds * 1000).toLocaleString()}</span>
//         </div>
//       ))}
//     </div>
//   );
// }

// export default ChatPage;













// --------------------------------below one is best ----------------------------------------------------------


// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { collection, query, onSnapshot, orderBy } from 'firebase/firestore';
// import { db, auth } from './firebaseConfig';
// import './ChatPage.css';

// function ChatPage() {
//   const { chatId } = useParams();
//   const [messages, setMessages] = useState([]);

//   useEffect(() => {
//     if (!auth.currentUser) {
//       console.error("User is not authenticated");
//       return;
//     }

//     const messagesRef = collection(db, 'chats', chatId, 'messages');
//     const q = query(messagesRef, orderBy('timestamp', 'asc'));

//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const msgs = snapshot.docs.map(doc => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       setMessages(msgs);
//     });

//     return () => unsubscribe();
//   }, [chatId]);

//   return (
//     <div className="chat-page">
//       {messages.map((msg) => (
//         <div key={msg.id} className={`chat-message ${msg.senderId === auth.currentUser.uid ? 'sent' : 'received'}`}>
//           {msg.text}
//           <span className="timestamp">{new Date(msg.timestamp?.seconds * 1000).toLocaleString()}</span>
//         </div>
//       ))}
//     </div>
//   );
// }

// export default ChatPage;





// --------------------------------------------above one is best ------------------------------------------------






// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { collection, addDoc, query, where, onSnapshot, orderBy,doc, updateDoc } from 'firebase/firestore';
// import { db, auth } from './firebaseConfig';
// import './ChatPage.css';

// function ChatPage() {
//   const { chatId } = useParams();
//   const [messages, setMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState("");

//   useEffect(() => {
//     if (!auth.currentUser) {
//       console.error("User is not authenticated");
//       return;
//     }

//     const messagesRef = collection(db, 'chats', chatId, 'messages');
//     const q = query(messagesRef, orderBy('timestamp', 'asc'));

//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const msgs = snapshot.docs.map(doc => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       setMessages(msgs);
//     });

//     return () => unsubscribe();
//   }, [chatId]);



// // ----------------------------------------------------------------------------------------
//   // const sendMessage = async () => {
//   //   if (newMessage.trim() === "") return;

//   //   const messagesRef = collection(db, 'chats', chatId, 'messages');
//   //   await addDoc(messagesRef, {
//   //     text: newMessage,
//   //     senderId: auth.currentUser.uid,
//   //     timestamp: new Date(),
//   //   });

//   //   setNewMessage("");
//   // };


//   // -----------------------------------------------------------------------------------------------



//   const sendMessage = async () => {
//     if (newMessage.trim() === "") return;
  
//     const chatDocRef = doc(db, 'chats', chatId);
//     const messagesRef = collection(chatDocRef, 'messages');
//     const timestamp = new Date();
  
//     await addDoc(messagesRef, {
//       text: newMessage,
//       senderId: auth.currentUser.uid,
//       timestamp,
//     });
  
//     // Update the chat document's last message time
//     await updateDoc(chatDocRef, {
//       lastMessageTime: timestamp,
//     });
  
//     setNewMessage("");
//   };
  

//   return (
//     <div className="chat-page">
//       <div className="messages">
//         {messages.map((msg) => (
//           <div key={msg.id} className={`chat-message ${msg.senderId === auth.currentUser.uid ? 'sent' : 'received'}`}>
//             {msg.text}
//             <span className="timestamp">{new Date(msg.timestamp?.seconds * 1000).toLocaleString()}</span>
//           </div>
//         ))}
//       </div>
//       <div className="input-area">
//         <input
//           type="text"
//           value={newMessage}
//           onChange={(e) => setNewMessage(e.target.value)}
//           placeholder="Type a message"
//         />
//         <button onClick={sendMessage}>Send</button>
//       </div>
//     </div>
//   );
// }

// export default ChatPage;












// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { collection, query, onSnapshot, orderBy, addDoc, serverTimestamp } from 'firebase/firestore';
// import { db, auth } from './firebaseConfig';
// import './ChatPage.css';

// function ChatPage() {
//   const { chatId } = useParams();
//   const [messages, setMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState("");

//   useEffect(() => {
//     if (!auth.currentUser) {
//       console.error("User is not authenticated");
//       return;
//     }

//     const messagesRef = collection(db, 'chats', chatId, 'messages');
//     const q = query(messagesRef, orderBy('timestamp', 'asc'));

//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const msgs = snapshot.docs.map(doc => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       setMessages(msgs);
//     });

//     return () => unsubscribe();
//   }, [chatId]);

//   const sendMessage = async () => {
//     if (newMessage.trim() === "") return;

//     const messagesRef = collection(db, 'chats', chatId, 'messages');

//     await addDoc(messagesRef, {
//       text: newMessage,
//       senderId: auth.currentUser.uid,
//       timestamp: serverTimestamp(),
//     });

//     setNewMessage("");
//   };

//   return (
//     <div className="chat-page">
//       <div className="messages">
//         {messages.map((msg) => (
//           <div key={msg.id} className={`chat-message ${msg.senderId === auth.currentUser.uid ? 'sent' : 'received'}`}>
//             <div className="message-text">{msg.text}</div>
//             <span className="timestamp">
//               {new Date(msg.timestamp?.seconds * 1000).toLocaleString()}
//             </span>
//           </div>
//         ))}
//       </div>
//       <div className="input-area">
//         <input
//           type="text"
//           value={newMessage}
//           onChange={(e) => setNewMessage(e.target.value)}
//           placeholder="Type a message"
//         />
//         <button onClick={sendMessage}>Send</button>
//       </div>
//     </div>
//   );
// }

// export default ChatPage;










// import React, { useState, useEffect } from 'react';
// import { useParams, useLocation  } from 'react-router-dom';
// import { collection, query, onSnapshot, orderBy, addDoc, serverTimestamp, where } from 'firebase/firestore';
// import { db, auth } from './firebaseConfig';
// import './ChatPage.css';

// function ChatPage() {
//   const { chatId } = useParams();
//   const location = useLocation();
//   const [messages, setMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState("");
//   const postOwnerId = location.state.postOwnerId;
//   // const [postOwnerId, setPostOwnerId] = useState(""); // Add state to store post owner's ID


//   useEffect(() => {
//     if (!auth.currentUser) {
//       console.error("User is not authenticated");
//       return;
//     }

//     // Fetch the chat and the post owner details
//     const messagesRef = collection(db, 'chats', chatId, 'messages');
//     const q = query(
//       messagesRef,
//       // where( auth.currentUser.uid === post.userId ),
//       orderBy('timestamp', 'asc')
//     );


    
  

//     // const unsubscribe = onSnapshot(q, (snapshot) => {
//     //   const msgs = snapshot.docs.map(doc => ({
//     //     id: doc.id,
//     //     ...doc.data()
//     //   }));
//     //   console.log(msgs);
//     //   setMessages(msgs);
//     // });

//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const msgs = snapshot.docs.map(doc => doc.data());
//       setMessages(msgs);
//       });


    

//     return () => unsubscribe();
//   }, [chatId]);

//   // const sendMessage = async () => {
//   //   if (newMessage.trim() === "") return;

//   //   const messagesRef = collection(db, 'chats', chatId, 'messages');
    
//   //   // Determine recipientId (if the current user is the sender, the recipient is the post owner, and vice versa)
//   //   const recipientId = auth.currentUser.uid === postOwnerId ? "POST_OWNER_ID" : auth.currentUser.uid;

//   //   await addDoc(messagesRef, {
//   //     text: newMessage,
//   //     senderId: auth.currentUser.uid,
//   //     recipientId: recipientId,
//   //     timestamp: serverTimestamp(),
//   //   });

//   //   setNewMessage("");
//   // };




//   const sendMessage = async () => {
//     if (newMessage.trim() === "") return;
//     try {
//       const messagesRef = collection(db, 'chats', chatId, 'messages');
//       await addDoc(messagesRef, {
//         text: newMessage,
//         senderId: auth.currentUser.uid,
//         recipientId: postOwnerId,
//         timestamp: serverTimestamp(),
//       });
    
//       setNewMessage("");
//     } catch (error) {
//       console.error("Error sending message:", error);
//     }
//   }    

//   return (
//     <div className="chat-page">
//       <div className="messages">
//       {messages.map((msg, index) => (
//           <div key={msg.id} className={`chat-message ${msg.senderId === auth.currentUser.uid ? 'sent' : 'received'}`}>
//             <div className="message-text">{msg.text}</div>
//             <span className="timestamp">
//               {new Date(msg.timestamp?.seconds * 1000).toLocaleString()}
//             </span>
//           </div>
//         ))}
//       </div>
//       <div className="input-area">
//         <input
//           type="text"
//           value={newMessage}
//           onChange={(e) => setNewMessage(e.target.value)}
//           placeholder="Type a message"
//         />
//         <button onClick={sendMessage}>Send</button>
//          {auth.currentUser.uid}
//          {/* {recipientId} */}
//          {postOwnerId}
//          {/* <p>Post Owner ID: {post.userId}</p>  */}
//       </div>       
//     </div>
//   );
// }

// export default ChatPage;















import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { collection, query, onSnapshot, orderBy, addDoc, serverTimestamp } from 'firebase/firestore';
import { db, auth } from './firebaseConfig';
import { usePost } from './PostContext'; // Import usePost
import './ChatPage.css';

function ChatPage() {
  const { chatId } = useParams();
  const location = useLocation();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const postOwnerId = location.state.postOwnerId; // Access postOwnerId from location
  const post = usePost(); // Access the post data
 

  useEffect(() => {
    if (!auth.currentUser) {
      console.error("User is not authenticated");
      return;
    }

    const messagesRef = collection(db, 'chats', chatId, 'messages');
    const q = query(messagesRef, orderBy('timestamp', 'asc'));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const msgs = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setMessages(msgs);
    });

    return () => unsubscribe();
  }, [chatId]);

  const sendMessage = async () => {
    if (newMessage.trim() === "") return;

    const messagesRef = collection(db, 'chats', chatId, 'messages');
    await addDoc(messagesRef, {
      text: newMessage,
      senderId: auth.currentUser.uid,
      recipientId: postOwnerId,
      timestamp: serverTimestamp(),
    });

    setNewMessage("");
  };

  return (
    <div className="chat-page">
      <div className="messages">
        {messages.map((msg) => (
          <div key={msg.id} className={`chat-message ${msg.senderId === auth.currentUser.uid ? 'sent' : 'received'}`}>
            <div className="message-text">{msg.text}</div>
            <span className="timestamp">
              {new Date(msg.timestamp?.seconds * 1000).toLocaleString()}
            </span>
          </div>
        ))}
      </div>
      <div className="input-area">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message"
        />
        <button onClick={sendMessage}>Send</button>
        <h2>Chat with {post.name}</h2>
      </div>
    </div>
  );
}

export default ChatPage;

