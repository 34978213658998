// // PostContext.js
// import React, { createContext, useContext, useState, useEffect } from 'react';
// import { doc, getDoc } from 'firebase/firestore';
// import { db } from './firebaseConfig';

// // Create a context
// const PostContext = createContext();

// // Custom hook to use the PostContext
// export const usePost = () => useContext(PostContext);

// // PostProvider component
// export const PostProvider = ({ children }) => {
//   const [post, setPost] = useState(null);

//   const fetchPost = async (postId) => {
//     try {
//       const postDoc = await getDoc(doc(db, 'posts', postId));
//       if (postDoc.exists()) {
//         setPost({ id: postDoc.id, ...postDoc.data() });
//       } else {
//         console.error('Post not found!');
//       }
//     } catch (error) {
//       console.error('Error fetching post:', error);
//     }
//   };

//   return (
//     <PostContext.Provider value={{ post, fetchPost }}>
//       {children}
//     </PostContext.Provider>
//   );
// };








import React, { createContext, useContext, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';

const PostContext = createContext();

export const usePost = () => useContext(PostContext);

export const PostProvider = ({ children }) => {
  const [post, setPost] = useState(null);

  const fetchPost = async (postId) => {
    try {
      const postDoc = await getDoc(doc(db, 'posts', postId));
      if (postDoc.exists()) {
        setPost({ id: postDoc.id, ...postDoc.data() });
      } else {
        console.error('Post not found!');
      }
    } catch (error) {
      console.error('Error fetching post:', error);
    }
  };

  return (
    <PostContext.Provider value={{ post, fetchPost }}>
      {children}
    </PostContext.Provider>
  );
};

