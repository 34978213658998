// import React, { useState } from 'react';
// import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
// import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
// import { db, storage } from './firebase';
// import './UploadPost.css';

// function UploadPost({ user }) {
//   const [title, setTitle] = useState('');
//   const [content, setContent] = useState('');
//   const [images, setImages] = useState([]);

//   const handleImageChange = (e) => {
//     setImages([...e.target.files]);
//   };

//   const handleUpload = async () => {
//     const imageUrls = await Promise.all(
//       images.map(async (image) => {
//         const imageRef = ref(storage, `posts/${user.uid}/${image.name}`);
//         const snapshot = await uploadBytesResumable(imageRef, image);
//         return await getDownloadURL(snapshot.ref);
//       })
//     );

//     await addDoc(collection(db, 'posts'), {
//       title,
//       content,
//       userId: user.uid,
//       username: user.displayName,
//       createdAt: serverTimestamp(),
//       imageUrls // Ensure this is an array
//     });

//     setTitle('');
//     setContent('');
//     setImages([]);
//   };

//   return (
//     <div className="upload-post">
//       <h2>Upload Post</h2>
//       <input
//         type="text"
//         placeholder="Title"
//         value={title}
//         onChange={(e) => setTitle(e.target.value)}
//       />
//       <textarea
//         placeholder="Content"
//         value={content}
//         onChange={(e) => setContent(e.target.value)}
//       ></textarea>
//       <input
//         type="file"
//         multiple
//         onChange={handleImageChange}
//       />
//       <button className="upload-button" onClick={handleUpload}>Upload</button>
//     </div>
//   );
// }

// export default UploadPost;






// import React, { useState } from 'react';
// import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
// import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
// import { db, storage } from './firebase';
// import './UploadPost.css';

// function UploadPost({ user }) {
//   const [title, setTitle] = useState('');
//   const [content, setContent] = useState('');
//   const [images, setImages] = useState([]);

//   const handleImageChange = (e) => {
//     setImages([...e.target.files]);
//   };

//   const handleUpload = async () => {
//     const imageUrls = await Promise.all(
//       images.map(async (image) => {
//         const imageRef = ref(storage, `posts/${user.uid}/${image.name}`);
//         const snapshot = await uploadBytesResumable(imageRef, image);
//         return await getDownloadURL(snapshot.ref);
//       })
//     );

//     await addDoc(collection(db, 'posts'), {
//       title,
//       content,
//       userId: user.uid,
//       username: user.displayName,
//       createdAt: serverTimestamp(),
//       imageUrls
//     });

//     setTitle('');
//     setContent('');
//     setImages([]);
//   };

//   return (
//     <div className="upload-post">
//       <h2>Upload Post</h2>
//       <input
//         type="text"
//         placeholder="Title"
//         value={title}
//         onChange={(e) => setTitle(e.target.value)}
//       />
//       <textarea
//         placeholder="Content"
//         value={content}
//         onChange={(e) => setContent(e.target.value)}
//       ></textarea>
//       <input
//         type="file"
//         multiple
//         onChange={handleImageChange}
//       />
//       <button className="upload-button" onClick={handleUpload}>Upload</button>
//     </div>
//   );
// }

// export default UploadPost;






































//----------------------------------------------------------------------------------------------------------------------------------------


// import React, { useState } from 'react';
// import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
// import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
// import { db, storage } from './firebase';
// import './UploadPost.css';

// function UploadPost({ user, onUploadSuccess }) {
//   const [title, setTitle] = useState('');
//   const [content, setContent] = useState('');
//   const [images, setImages] = useState([]);

//   const handleImageChange = (e) => {
//     setImages([...e.target.files]);
//   };

//   const handleUpload = async () => {
//     const imageUrls = await Promise.all(
//       images.map(async (image) => {
//         const imageRef = ref(storage, `posts/${user.uid}/${image.name}`);
//         const snapshot = await uploadBytesResumable(imageRef, image);
//         return await getDownloadURL(snapshot.ref);
//       })
//     );

//     await addDoc(collection(db, 'posts'), {
//       title,
//       content,
//       userId: user.uid,
//       username: user.displayName,
//       createdAt: serverTimestamp(),
//       imageUrls
//     });

//     setTitle('');
//     setContent('');
//     setImages([]);
//     onUploadSuccess();  
//   };

//   return (
//     <div className="upload-post">
//       <h2>Upload Post</h2>
//       <input
//         type="text"
//         placeholder="Title"
//         value={title}
//         onChange={(e) => setTitle(e.target.value)}
//       />
//       <textarea
//         placeholder="Content"
//         value={content}
//         onChange={(e) => setContent(e.target.value)}
//       ></textarea>
//       <input
//         type="file"
//         multiple
//         onChange={handleImageChange}
//       />
//       <button className="upload-button" onClick={handleUpload}>Upload</button>
//     </div>
//   );
// }

// export default UploadPost;

//----------------------------------------------------------------------------------------------------------------------------------------------------------------------








// import React, { useState } from 'react';
// import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
// import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
// import { db, storage } from './firebaseConfig';
// import './UploadPost.css';

// function UploadPost({ user, onUploadSuccess }) {
//   const [title, setTitle] = useState('');
//   const [content, setContent] = useState('');
//   const [images, setImages] = useState([]);

//   const handleImageChange = (e) => {
//     setImages([...e.target.files]);
//   };

//   const handleUpload = async () => {
//     const imageUrls = await Promise.all(
//       images.map(async (image) => {
//         const imageRef = ref(storage, `posts/${user.uid}/${image.name}`);
//         const snapshot = await uploadBytesResumable(imageRef, image);
//         return await getDownloadURL(snapshot.ref);
//       })
//     );

//     await addDoc(collection(db, 'posts'), {
//       title,
//       content,
//       userId: user.uid,
//       username: user.displayName,
//       createdAt: serverTimestamp(),
//       imageUrls
//     });

//     setTitle('');
//     setContent('');
//     setImages([]);
//     onUploadSuccess();  
//   };

//   return (
//     <div className="upload-post">
//       <h2>Upload Post</h2>
//       <input
//         type="text"
//         placeholder="Title"
//         value={title}
//         onChange={(e) => setTitle(e.target.value)}
//       />
//       <textarea
//         placeholder="Content"
//         value={content}
//         onChange={(e) => setContent(e.target.value)}
//       ></textarea>
//       <input
//         type="file"
//         multiple
//         onChange={handleImageChange}
//       />
//       <button className="upload-button" onClick={handleUpload}>Upload</button>
//     </div>
//   );
// }

// export default UploadPost;









// import React, { useState } from 'react';
// import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
// import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
// import { db, storage } from './firebase';
// import './UploadPost.css';

// function UploadPost({ user, onUploadSuccess }) {
//   const [title, setTitle] = useState('');
//   const [content, setContent] = useState('');
//   const [images, setImages] = useState([]);

//   const handleImageChange = (e) => {
//     setImages([...e.target.files]);
//   };

//   const handleUpload = async () => {
//     const imageUrls = await Promise.all(
//       images.map(async (image) => {
//         const imageRef = ref(storage, `posts/${user.uid}/${image.name}`);
//         const snapshot = await uploadBytesResumable(imageRef, image);
//         return await getDownloadURL(snapshot.ref);
//       })
//     );

//     await addDoc(collection(db, 'posts'), {
//       title,
//       content,
//       userId: user.uid,
//       username: user.displayName,
//       createdAt: serverTimestamp(),
//       imageUrls
//     });

//     setTitle('');
//     setContent('');
//     setImages([]);
//     onUploadSuccess(); // Call the success handler
//   };

//   return (
//     <div className="upload-post">
//       <h2>Upload Post</h2>
//       <input
//         type="text"
//         placeholder="Title"
//         value={title}
//         onChange={(e) => setTitle(e.target.value)}
//       />
//       <textarea
//         placeholder="Content"
//         value={content}
//         onChange={(e) => setContent(e.target.value)}
//       ></textarea>
//       <input
//         type="file"
//         multiple
//         onChange={handleImageChange}
//       />
//       <button className="upload-button" onClick={handleUpload}>Upload</button>
//     </div>
//   );
// }

// export default UploadPost;










// import React, { useState } from 'react';
// import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
// import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
// import { db, storage } from './firebase';
// import './UploadPost.css';

// function UploadPost({ user, onUploadSuccess }) {
//   const [title, setTitle] = useState('');
//   const [content, setContent] = useState('');
//   const [images, setImages] = useState([]);
//   const [uploading, setUploading] = useState(false);
//   const [error, setError] = useState('');

//   const handleImageChange = (e) => {
//     setImages([...e.target.files]);
//   };

//   const handleUpload = async () => {
//     if (!title || !content) {
//       setError('Title and content are required.');
//       return;
//     }
//     setError('');
//     setUploading(true);

//     try {
//       const imageUrls = await Promise.all(
//         images.map(async (image) => {
//           const imageRef = ref(storage, `posts/${user.uid}/${image.name}`);
//           const snapshot = await uploadBytesResumable(imageRef, image);
//           return await getDownloadURL(snapshot.ref);
//         })
//       );

//       await addDoc(collection(db, 'posts'), {
//         title,
//         content,
//         userId: user.uid,
//         username: user.displayName,
//         createdAt: serverTimestamp(),
//         imageUrls
//       });

//       setTitle('');
//       setContent('');
//       setImages([]);
//       onUploadSuccess(); // Call the success handler
//     } catch (err) {
//       setError('Failed to upload post. Please try again.');
//     } finally {
//       setUploading(false);
//     }
//   };

//   return (
//     <div className="upload-post">
//       <h2>Upload Post</h2>
//       {error && <div className="error-message">{error}</div>}
//       <input
//         type="text"
//         placeholder="Title"
//         value={title}
//         onChange={(e) => setTitle(e.target.value)}
//       />
//       <textarea
//         placeholder="Content"
//         value={content}
//         onChange={(e) => setContent(e.target.value)}
//       ></textarea>
//       <input
//         type="file"
//         multiple
//         onChange={handleImageChange}
//       />
//       <button className="upload-button" onClick={handleUpload} disabled={uploading}>
//         {uploading ? 'Uploading...' : 'Upload'}
//       </button>
//     </div>
//   );
// }

// export default UploadPost;



// import React, { useState } from 'react';
// import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
// import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
// import { db, storage } from './firebase';
// import './UploadPost.css';

// function UploadPost({ user, onUploadSuccess }) {
//   const [title, setTitle] = useState('');
//   const [content, setContent] = useState('');
//   const [images, setImages] = useState([]);
//   const [uploading, setUploading] = useState(false);
//   const [error, setError] = useState('');

//   const handleImageChange = (e) => {
//     setImages([...e.target.files]);
//   };

//   const handleUpload = async () => {
//     if (!title || !content) {
//       setError('Title and content are required.');
//       return;
//     }
//     setError('');
//     setUploading(true);

//     try {
//       const imageUrls = await Promise.all(
//         images.map(async (image) => {
//           const imageRef = ref(storage, `posts/${user.uid}/${image.name}`);
//           const snapshot = await uploadBytesResumable(imageRef, image);
//           return await getDownloadURL(snapshot.ref);
//         })
//       );

//       await addDoc(collection(db, 'posts'), {
//         title,
//         content,
//         userId: user.uid,
//         username: user.displayName,
//         createdAt: serverTimestamp(),
//         imageUrls
//       });

//       setTitle('');
//       setContent('');
//       setImages([]);
//       onUploadSuccess(); // Call the success handler
//     } catch (err) {
//       setError('Failed to upload post. Please try again.');
//     } finally {
//       setUploading(false);
//     }
//   };

//   return (
//     <div className="upload-post">
//       <h2>Upload Post</h2>
//       {error && <div className="error-message">{error}</div>}
//       <input
//         type="text"
//         placeholder="Title"
//         value={title}
//         onChange={(e) => setTitle(e.target.value)}
//       />
//       <textarea
//         placeholder="Content"
//         value={content}
//         onChange={(e) => setContent(e.target.value)}
//       ></textarea>
//       <input
//         type="file"
//         multiple
//         onChange={handleImageChange}
//       />
//       <button className="upload-button" onClick={handleUpload} disabled={uploading}>
//         {uploading ? 'Uploading...' : 'Upload'}
//       </button>
//     </div>
//   );
// }

// export default UploadPost;





// import React, { useState } from 'react';
// import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
// import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
// import { db, storage } from './firebase';
// import './UploadPost.css';

// function UploadPost({ user, onUploadSuccess }) {
//   const [title, setTitle] = useState('');
//   const [content, setContent] = useState('');
//   const [images, setImages] = useState([]);
//   const [uploading, setUploading] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState(false);

//   const handleImageChange = (e) => {
//     setImages([...e.target.files]);
//   };

//   const handleUpload = async () => {
//     if (!title || !content) {
//       setError('Title and content are required.');
//       return;
//     }
//     setError('');
//     setUploading(true);

//     try {
//       const imageUrls = await Promise.all(
//         images.map(async (image) => {
//           const imageRef = ref(storage, `posts/${user.uid}/${image.name}`);
//           const snapshot = await uploadBytesResumable(imageRef, image);
//           return await getDownloadURL(snapshot.ref);
//         })
//       );

//       await addDoc(collection(db, 'posts'), {
//         title,
//         content,
//         userId: user.uid,
//         username: user.displayName,
//         createdAt: serverTimestamp(),
//         imageUrls
//       });

//       setTitle('');
//       setContent('');
//       setImages([]);
//       setSuccess(true); // Show success popup
//       setTimeout(() => setSuccess(false), 3000); // Hide the success popup after 3 seconds
//       onUploadSuccess(); // Call the success handler
//     } catch (err) {
//       setError('Failed to upload post. Please try again.');
//     } finally {
//       setUploading(false);
//     }
//   };

//   return (
//     <div className="upload-post">
//       <h2>Upload Post</h2>
//       {error && <div className="error-message">{error}</div>}
//       {success && <div className="success-message">Successfully uploaded!</div>}
//       <input
//         type="text"
//         placeholder="Title"
//         value={title}
//         onChange={(e) => setTitle(e.target.value)}
//       />
//       <textarea
//         placeholder="Content"
//         value={content}
//         onChange={(e) => setContent(e.target.value)}
//       ></textarea>
//       <input
//         type="file"
//         multiple
//         onChange={handleImageChange}
//       />
//       <button className="upload-button" onClick={handleUpload} disabled={uploading}>
//         {uploading ? 'Uploading...' : 'Upload'}
//       </button>
//     </div>
//   );
// }

// export default UploadPost;




// // Import React and useState from react
// import React, { useState } from 'react';
// // Import necessary methods from Firebase
// import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
// import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
// // Import db and storage from the Firebase initialization file
// import { db, storage } from './firebaseConfig';
// // Import CSS for styling
// import './UploadPost.css';

// function UploadPost({ user, onUploadSuccess }) {
//   const [title, setTitle] = useState('');
//   const [content, setContent] = useState('');
//   const [images, setImages] = useState([]);
//   const [uploading, setUploading] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState(false);

//   // Handle image selection
//   const handleImageChange = (e) => {
//     setImages([...e.target.files]);
//   };

//   // Handle the upload process
//   const handleUpload = async () => {
//     // Check if title and content are provided
//     if (!title || !content) {
//       setError('Title and content are required.');
//       return;
//     }
//     setError('');
//     setUploading(true);

//     try {
//       console.log('Uploading images...');
//       // Upload images and get their URLs
//       const imageUrls = await Promise.all(
//         images.map(async (image) => {
//           const imageRef = ref(storage, `posts/${user.uid}/${image.name}`);
//           const snapshot = await uploadBytesResumable(imageRef, image);
//           const url = await getDownloadURL(snapshot.ref);
//           console.log(`Uploaded image URL: ${url}`);
//           return url;
//         })
//       );

//       console.log('Adding post to Firestore...');
//       // Add post details to Firestore
//       await addDoc(collection(db, 'posts'), {
//         title,
//         content,
//         userId: user.uid,
//         username: user.displayName,
//         createdAt: serverTimestamp(),
//         imageUrls
//       });

//       console.log('Post added successfully');
//       setTitle('');
//       setContent('');
//       setImages([]);
//       setSuccess(true); // Show success popup
//       setTimeout(() => setSuccess(false), 3000); // Hide the success popup after 3 seconds
//       onUploadSuccess(); // Call the success handler
//     } catch (err) {
//       console.error('Failed to upload post:', err);
//       setError('Failed to upload post. Please try again.');
//     } finally {
//       setUploading(false);
//     }
//   };

//   return (
//     <div className="upload-post">
//       <h2>Upload Post</h2>
//       {error && <div className="error-message">{error}</div>}
//       {success && <div className="success-message">Successfully uploaded!</div>}
//       <input
//         type="text"
//         placeholder="Title"
//         value={title}
//         onChange={(e) => setTitle(e.target.value)}
//       />
//       <textarea
//         placeholder="Content"
//         value={content}
//         onChange={(e) => setContent(e.target.value)}
//       ></textarea>
//       <input
//         type="file"
//         multiple
//         onChange={handleImageChange}
//       />
//       <button className="upload-button" onClick={handleUpload} disabled={uploading}>
//         {uploading ? 'Uploading...' : 'Upload'}
//       </button>
//     </div>
//   );
// }

// export default UploadPost;






//----------------------------------------------------------------------------------------------------------

          // NEW CODE From different account
          //below
//----------------------------------------------------------------------------------------------------------


import React, { useState } from 'react';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db, storage } from './firebaseConfig';
import './UploadPost.css';

function UploadPost({ user, onUploadSuccess }) {
  const [title, setTitle] = useState('');
  const [year, setYear] = useState('');
  const [titlename, setTitlename] = useState('');
  const [name, setName] = useState('');
  const [collegename, setCollegename] = useState('');
  const [price, setPrice] = useState('');
  const [contact, setContact] = useState('');
  const [content, setContent] = useState('');
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');

  const handleImageChange = (e) => {
    setImages([...e.target.files]);
  };

  const handleUpload = async () => {
    setUploading(true);
    setError('');

    try {
      const imageUrls = await Promise.all(
        images.map(async (image) => {
          const imageRef = ref(storage, `posts/${user.uid}/${image.name}`);
          const snapshot = await uploadBytesResumable(imageRef, image);
          return await getDownloadURL(snapshot.ref);
        })
      );


      await addDoc(collection(db, 'posts'), {
        title: title || '',
        year: year || '',
        titlename: titlename || '',
        name: name || '',
        collegename: collegename || '',
        price: price || '',
        contact: contact || '',
        content: content || '',
        userId: user.uid,
        username: user.displayName,
        createdAt: serverTimestamp(),
        imageUrls

      // await addDoc(collection(db, 'posts'), {
      //   title,
      //   year,
      //   titlename,
      //   name,
      //   collegename,
      //   price,
      //   contact,
      //   content,
      //   userId: user.uid,
      //   username: user.displayName,
      //   createdAt: serverTimestamp(),
      //   imageUrls
      });

      setTitle('');
      setYear('');
      setTitlename('');
      setName('');
      setCollegename('');
      setPrice('');
      setContact('');
      setContent('');
      setImages([]);
      onUploadSuccess();
    } catch (error) {
      console.error("Error uploading post: ", error);
      setError("Failed to upload post. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="upload-post">
      <h2>Upload Post</h2>
      {error && <p className="error-message">{error}</p>}
      <input
        type="text"
        placeholder="Your Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        disabled={uploading}
      />
      <input
        type="text"
        placeholder="Degree"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        disabled={uploading}
      />
      <input
        type="text"
        placeholder="year"
        value={year}
        onChange={(e) => setYear(e.target.value)}
        disabled={uploading}
      />
      <input
        type="text"
        placeholder="Book name"
        value={titlename}
        onChange={(e) => setTitlename(e.target.value)}
        disabled={uploading}
      />
      <input
        type="text"
        placeholder="college name"
        value={collegename}
        onChange={(e) => setCollegename(e.target.value)}
        disabled={uploading}
      />
      <input
        type="text"
        placeholder="Price (negotiable or non negotiable)"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        disabled={uploading}
      />
      <input
        type="text"
        placeholder="contact: Whatsapp or Email etc. "
        value={contact}
        onChange={(e) => setContact(e.target.value)}
        disabled={uploading}
      />
      <textarea
        placeholder="describe product"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        disabled={uploading}
      ></textarea>
      <input
        type="file"
        multiple
        onChange={handleImageChange}
        disabled={uploading}
      />
      <button
        className="upload-button"
        onClick={handleUpload}
        disabled={uploading}
      >
        {uploading ? 'Uploading...' : 'Upload'}
      </button>
    </div>
  );
}

export default UploadPost;
