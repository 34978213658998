import React, {useEffect} from "react";
import "./About.css";
// import Footer from "../Footer/Footer";
import image1 from "./Image/image1.png";
import image2 from "./Image/image2.png";
import circle from "./Image/circle.png";
import group1 from './Image/Group2.png';
import Vision from "./Image/bulb1.jpg";
import treecricle from "./Image/treecircle.png";
import books from "./Image/books.png"
import AOS from 'aos';
import 'aos/dist/aos.css';

function About() {


    useEffect(()=>{
        AOS.init({duration:2000});
    },[])


  return (
    <div>
      {/* <div className="aboutus">
        <h1>ABOUT US</h1>
        <p>
          Dedicated to providing exceptional care and services, we ensure your
          pets receive the love, attention, and expertise they deserve.
        </p>
      </div> */}

      {/* ---------------------------second page---------------------------- */}

     
<div className="aboutus-page2">
    <h1  data-aos="flip-up" >Purpose <img src={books} alt="Freepik"  className="tag-line" /></h1>
    <div className="aboutus-page22">
        <div className="aboutus-page23">
            <h2> Vision</h2>
            <p className="section-paragraph" data-aos="fade-up">
            To create a sustainable and supportive educational community where
            senior students can easily pass on their resources, such as books, to
            junior students, promoting affordability, reducing waste, and
            fostering a collaborative learning environment. TurnOnSell.com aims to
            become the go-to platform for students looking to buy or sell books
            and other educational materials, making education more accessible and
            environmentally friendly for everyone.
        </p>
        </div>
        <div className="image-container">
            <img src={circle} alt="circle" className="circle-image" />
            <img src={Vision} alt="image" className="main-image" data-aos="zoom-in-left"/>
        </div>
    </div>
    <div className="aboutus-page22 reverse">
    <div className="aboutus-page23">
            <h2 > Mission</h2>
            <p className="section-paragraph" data-aos="fade-up">
            In the market, some stores buy and sell used books, but they take a
            certain margin and impose conditions, such as requiring a store stamp
            on the books📚. Not all stores provide the stamp system, leaving
            students with no option but to sell their books to scrap dealers who
            buy books on per kg basis, which doesn’t reflect the true value of the
            book. Many students also want to buy books📚 at half price.
        </p>
        <p className="section-paragraph" data-aos="fade-up">
            Our mission is to provide students with a reliable and user-friendly
            platform where senior students can easily sell their old books, and
            junior students can buy those books at affordable prices.
        </p>
        </div>
        <div className="image-container">
            <img src={circle} alt="circle" className="circle-image" />
            <img src={treecricle} alt="image2" className="main-image" data-aos="zoom-in-right" />
        </div>
       
    </div>     
</div>
<div className="aboutus-bottom">
            <h1  data-aos="flip-up" >Message</h1>
            <p>"Recommend teachers and students to share this in your student and friends' WhatsApp groups."</p>
            <h6>-TurnOnSell.com</h6>
           
        </div>

    </div>
  );
}

export default About;
