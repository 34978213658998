// import React, { useEffect, useState } from 'react';
// import { collection, onSnapshot, query } from 'firebase/firestore';
// import { db } from './firebase';
// import Post from './Post';
// import './Search.css';

// function Search({ user }) {
//   const [posts, setPosts] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');

//   useEffect(() => {
//     const q = query(collection(db, 'posts'));
//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const postsData = snapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       setPosts(postsData);
//     });

//     return () => unsubscribe();
//   }, []);

//   const filteredPosts = posts.filter((post) =>
//     post.title.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   return (
//     <div className="search">
//       <input
//         type="text"
//         placeholder="Search by title"
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         className="search-bar"
//       />
//       <div className="post-list">
//         {filteredPosts.map((post) => (
//           <Post key={post.id} post={post} user={user} />
//         ))}
//       </div>
//     </div>
//   );
// }

// export default Search;


//--------------------------------------------------------------------------------------------------------------------------------------



// import React, { useEffect, useState } from 'react';
// import { collection, onSnapshot, query } from 'firebase/firestore';
// import { db } from './firebase';
// import Post from './Post';
// import './Search.css';

// function Search({ user }) {
//   const [posts, setPosts] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');

//   useEffect(() => {
//     const q = query(collection(db, 'posts'));
//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const postsData = snapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       setPosts(postsData);
//     });

//     return () => unsubscribe();
//   }, []);

//   const filteredPosts = posts.filter((post) =>
//     post.title.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   return (
//     <div className="search">
//       <input
//         type="text"
//         placeholder="Search by title"
//         value={searchTerm}
//         onChange={(e) => setSearchTerm(e.target.value)}
//         className="search-bar"
//       />
//       <div className="post-list">
//         {filteredPosts.length > 0 ? (
//           filteredPosts.map((post) => (
//             <Post key={post.id} post={post} user={user} />
//           ))
//         ) : (
//           <p>No posts found.</p>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Search;





//----------------------------------------------------------------------------------------------------------

          // NEW CODE From different account
          //below
//----------------------------------------------------------------------------------------------------------


import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { db } from './firebaseConfig';
import Post from './Post';
import './Search.css';

function Search({ user }) {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const q = query(collection(db, 'posts'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const postsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setPosts(postsData);
    });

    return () => unsubscribe();
  }, []);

  // const filteredPosts = posts.filter((post) =>
  //   post.title.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const filteredPosts = posts.filter((post) =>
    (post.title+" "+post.year+" "+ post.titlename+" "+post.collegename).toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="search">
      <input
        type="text"
        placeholder="Search by degree_year_bookname_college name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-bar"
      />
      <div className="post-list">
        {filteredPosts.length > 0 ? (
          filteredPosts.map((post) => (
            <Post key={post.id} post={post} user={user} />
          ))
        ) : (
          <p>No posts found.</p>
        )}
      </div>
    </div>
  );
}

export default Search;
