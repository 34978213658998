// import React, { useState, useEffect } from 'react';
// import { collection, query, where, onSnapshot } from 'firebase/firestore';
// import { db, auth } from './firebaseConfig';
// import { useNavigate } from 'react-router-dom';
// // import './ChatHistory.css';

// function ChatHistory() {
//   const [chats, setChats] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const user = auth.currentUser;
//     if (user) {
//       const q = query(collection(db, 'chats'), where('participants', 'array-contains', user.uid));
//       const unsubscribe = onSnapshot(q, (snapshot) => {
//         const chatList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//         setChats(chatList);
//       });
//       return () => unsubscribe();
//     }
//   }, []);

//   return (
//     <div className="chat-history">
//       <h2>Chat History</h2>
//       {chats.map(chat => (
//         <div key={chat.id} className="chat-item" onClick={() => navigate(`/chat/${chat.id}`)}>
//           <p>Chat with: {chat.participants.filter(p => p !== auth.currentUser.uid).join(', ')}</p>
//         </div>
//       ))}
//     </div>
//   );
// }

// export default ChatHistory;



// -----------------------------------below one is good --------------------------------


// import React, { useState, useEffect } from 'react';
// import { collection, query, where, onSnapshot } from 'firebase/firestore';
// import { db, auth } from './firebaseConfig';
// import { useNavigate } from 'react-router-dom';
// // import './ChatHistory.css';

// function ChatHistory() {
//   const [chats, setChats] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const user = auth.currentUser;
//     if (user) {
//       const q = query(collection(db, 'chats'), where('participants', 'array-contains', user.uid));
//       const unsubscribe = onSnapshot(q, (snapshot) => {
//         const chatList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//         setChats(chatList);
//       });
//       return () => unsubscribe();
//     }
//   }, []);

//   return (
//     <div className="chat-history">
//       <h2>Chat History</h2>
//       {chats.map(chat => (
//         <div key={chat.id} className="chat-item" onClick={() => navigate(`/chat/${chat.id}`)}>
//           <p>Chat with: {chat.participants.filter(p => p !== auth.currentUser.uid).join(', ')}</p>
//         </div>
//       ))}
//     </div>
//   );
// }

// export default ChatHistory;




// _________________________________________________ above one is good ---------------------------------------



// import React, { useState, useEffect } from 'react';
// import { collection, query, where, onSnapshot } from 'firebase/firestore';
// import { db, auth } from './firebaseConfig';
// import { useNavigate } from 'react-router-dom';

// function ChatHistory() {
//   const [chats, setChats] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const user = auth.currentUser;
//     if (user) {
//       const q = query(collection(db, 'chats'), where('participants', 'array-contains', user.uid));
//       const unsubscribe = onSnapshot(q, (snapshot) => {
//         const chatList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//         setChats(chatList);
//       });
//       return () => unsubscribe();
//     }
//   }, []);

//   const openChat = (chatId) => {
//     navigate(`/chat/${chatId}`);
    
//   };

//   return (
//     <div className="chat-history">
//       <h2>Chat History</h2>
//       {chats.map(chat => (
//         <div key={chat.id} className="chat-item" onClick={() => openChat(chat.id)}>
//           <p>Chat with: {chat.participants.filter(p => p !== auth.currentUser.uid).join(', ')}</p>
//         </div>
//       ))}
//     </div>
//   );
// }

// export default ChatHistory;



















// import React, { useState, useEffect } from 'react';
// import { collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';
// import { db, auth } from './firebaseConfig';
// import { useNavigate } from 'react-router-dom';

// function ChatHistory() {
//   const [chats, setChats] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const user = auth.currentUser;
//     if (user) {
//       const q = query(collection(db, 'chats'), where('participants', 'array-contains', user.uid), orderBy('lastMessageTime', 'desc') );
//       const unsubscribe = onSnapshot(q, (snapshot) => {
//         const chatList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//         setChats(chatList);
//       });
//       return () => unsubscribe();
//     }
//   }, []);

//   const openChat = (chat) => {
//     navigate(`/chat/${chat.postOwner}/${chat.id}`);
//   };

//   return (
//     <div className="chat-history">
//       <h2>Chat History</h2>
//       {chats.map(chat => (
//         <div key={chat.id} className="chat-item" onClick={() => openChat(chat)}>
//           <p>Chat with: {chat.participants.filter(p => p !== auth.currentUser.uid).join(', ')}</p>
//         </div>
//       ))}
//     </div>
//   );
// }

// export default ChatHistory;





















import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';
import { db, auth } from './firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { usePost } from './PostContext';

function ChatHistory() {
  const [chats, setChats] = useState([]);
  const navigate = useNavigate();
  // const post = usePost();
  const { post, fetchPost } = usePost();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const q = query(
        collection(db, 'chats'),
        where('participants', 'array-contains', user.uid),
        orderBy('lastMessageTime', 'desc')
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const chatList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setChats(chatList);
      });
      return () => unsubscribe();
    }
  }, []);

  // const openChat = (chat) => {
  //   // Navigate to ChatPage with only chatId as URL parameter
  //   navigate(`/chat/${chat.id}`);
  // };


  

  const openChat = async(chat) => {
    const postOwnerId = chat.participants.find(p => p !== auth.currentUser.uid);
    
    
     // Fetch the post data for this chat, assuming the postId is available in chat
     if (chat.postId) {
      await fetchPost(chat.postId);
    }

    navigate(`/chat/${chat.id}`, { state: { postOwnerId } });
  };


  return (
    <div className="chat-history">
      <h2>Chat History</h2>
      {chats.map(chat => (
        <div key={chat.id} className="chat-item" onClick={() => openChat(chat)}>
          <p>Chat with1: {chat.participants.filter(p => p !== auth.currentUser.uid).join(', ')}</p>
          <p>Chat with: {post?.name || 'Unknown'}</p>
          <p>Chat with2: {chat.postName}</p>
          <p>Chat with3: {chat.username}</p>
          <p className="timestamp">{new Date(chat.lastMessageTime?.seconds * 1000).toLocaleString()}</p>
          {/* <p>name:{post.name}</p>
          <p>Posted by: {post.username}</p>
      
      <p>Post ID: {post.id}</p>
      <p>Post Owner ID: {post.userId}</p>  */}
        </div>
      ))}
    </div>
  );
}

export default ChatHistory;

