// import React, { useEffect, useState } from 'react';
// import { collection, onSnapshot, query, where, deleteDoc, doc } from 'firebase/firestore';
// import { db } from './firebase';
// import Post from './Post';
// import './ProfileView.css';

// function ProfileView({ user }) {
//   const [posts, setPosts] = useState([]);

//   useEffect(() => {
//     const q = query(collection(db, 'posts'), where('userId', '==', user.uid));
//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const postsData = snapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       setPosts(postsData);
//     });

//     return () => unsubscribe();
//   }, [user.uid]);

//   const handleDelete = async (postId) => {
//     await deleteDoc(doc(db, 'posts', postId));
//   };

//   return (
//     <div className="profile-view">
//       <h2>Your Posts</h2>
//       {posts.map((post) => (
//         <Post key={post.id} post={post} user={user} onDelete={() => handleDelete(post.id)} />
//       ))}
//     </div>
//   );
// }

// export default ProfileView;







// import React, { useEffect, useState } from 'react';
// import { collection, onSnapshot, query, where, deleteDoc, doc } from 'firebase/firestore';
// import { db } from './firebase';
// import Post from './Post';
// import './ProfileView.css';

// function ProfileView({ user }) {
//   const [posts, setPosts] = useState([]);

//   useEffect(() => {
//     const q = query(collection(db, 'posts'), where('userId', '==', user.uid));
//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const postsData = snapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       setPosts(postsData);
//     });

//     return () => unsubscribe();
//   }, [user.uid]);

//   const handleDelete = async (postId) => {
//     await deleteDoc(doc(db, 'posts', postId));
//   };

//   return (
//     <div className="profile-view">
//       <h2>Your Posts</h2>
//       {posts.length > 0 ? (
//         posts.map((post) => (
//           <Post key={post.id} post={post} user={user} onDelete={() => handleDelete(post.id)} />
//         ))
//       ) : (
//         <p>No posts yet.</p>
//       )}
//     </div>
//   );
// }

// export default ProfileView;





//----------------------------------------------------------------------------------------------------------

          // NEW CODE From different account
          //below
//----------------------------------------------------------------------------------------------------------




import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, query, where, deleteDoc, doc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import Post from './Post';
import './ProfileView.css';

function ProfileView({ user }) {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const q = query(collection(db, 'posts'), where('userId', '==', user.uid));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const postsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setPosts(postsData);
    });

    return () => unsubscribe();
  }, [user.uid]);

  const handleDelete = async (postId) => {
    await deleteDoc(doc(db, 'posts', postId));
  };

  return (
    <div className="profile-view">
      <h2>Your Posts</h2>
      {posts.length > 0 ? (
        posts.map((post) => (
          <Post key={post.id} post={post} user={user} onDelete={() => handleDelete(post.id)} />
        ))
      ) : (
        <p>No posts yet.</p>
      )}
      
    </div>
  );
}

export default ProfileView;
