// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
// import { onAuthStateChanged, signOut } from 'firebase/auth';
// import { auth,db } from './firebaseConfig';
// import { collection, query, where, onSnapshot } from 'firebase/firestore';
// import SendIcon from '@mui/icons-material/Send';
// import ChatIcon from '@mui/icons-material/Chat';
// import PostList from './PostList';
// import UploadPost from './UploadPost';
// import ProfileView from './ProfileView';
// import PostDetails from './PostDetails';
// import Search from './Search';
// import SignIn from './Signin';
// import ChatHistory from './ChatHistory';
// import chat from './Chat';
// import './Appp.css';

// function Appp() {
//   const [user, setUser] = useState(null);
//   const [uploadSuccess, setUploadSuccess] = useState(false);

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       setUser(user);
//     });
//     return () => unsubscribe();
//   }, []);

//   const signOutUser = () => {
//     signOut(auth);
//   };

//   const handleUploadSuccess = () => {
//     setUploadSuccess(true);
//     setTimeout(() => setUploadSuccess(false), 3000); // Hide the success message after 3 seconds
//   };

//   return (
//     <Router>
//       <div className="App">
//         {user ? (
//           <>
//             <Header user={user} signOutUser={signOutUser} />
//             <div className="content">
//               {uploadSuccess && <div className="success-message">Successfully uploaded!</div>}
//               <Routes>
//                 <Route path="/" element={<PostList user={user} />} />
//                 <Route path="/search" element={<Search user={user} />} />
//                 <Route path="/profile" element={<ProfileView user={user} />} />
//                 <Route path="/upload" element={<UploadPost user={user} onUploadSuccess={handleUploadSuccess} />} />
//                 <Route path="/post/:id" element={<PostDetails />} />
//                 <Route path="/chat-history" element={<ChatHistory />} />
//               </Routes>
//             </div>
//           </>
//         ) : (
//           <SignIn />
//         )}
//       </div>
//     </Router>
//   );
// }

// function Header({ user, signOutUser }) {
//   const navigate = useNavigate();
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [unreadCount, setUnreadCount] = useState(0);


//   useEffect(() => {
//     if (user) {
//       const q = query(
//         collection(db, 'chats', user.uid, 'messages'),
//         where('seen', '==', false)
//       );

//       const unsubscribe = onSnapshot(q, (snapshot) => {
//         setUnreadCount(snapshot.size);
//       });

//       return () => unsubscribe();
//     }
//   }, [user]);

//   const toggleMenu = () => {
//     setMenuOpen(!menuOpen);
//   };

//   return (
//     <header className="App-header">
//       <div className="brand" onClick={() => navigate('/')}>TurnOnSell</div>
//       <div className="menu-button" onClick={toggleMenu}>
//         &#9776;
//       </div>
//       <nav className={`header-buttons ${menuOpen ? 'open' : ''}`}>
//         <button className="profile-link" onClick={() => navigate('/profile')}>Profile</button>
//         <button className="search-link" onClick={() => navigate('/search')}>Search</button>
//         <button className="upload-link" onClick={() => navigate('/upload')}>Upload Post</button>
//         <button className="signin-link" onClick={signOutUser}>Sign Out</button>
//         <div className="message-icon-container" onClick={() => navigate('/chat-history')}>
//           <ChatIcon style={{ fontSize: '2rem' }} />
//           {unreadCount > 0 && <span className="message-notification">{unreadCount}</span>}
//         </div>
//         {/* <button><SendIcon/></button> */}
//         {/* <button className="auth-button" onClick={signOutUser}>Sign Out</button> */}
//       </nav>
//     </header>
//   );
// }

// export default Appp;











import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth, db } from './firebaseConfig';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import ChatIcon from '@mui/icons-material/Chat';
import PostList from './PostList';
import UploadPost from './UploadPost';
import ProfileView from './ProfileView';
import PostDetails from './PostDetails';
import ChatPage from './ChatPage';
import Search from './Search';
import SignIn from './Signin';
import ChatHistory from './ChatHistory';
import Chat from './Chat'; // Ensure this is used somewhere or remove the import
import { useParams } from 'react-router-dom';
import { PostProvider } from './PostContext';
import introJs from 'intro.js';  // Import intro.js
import 'intro.js/introjs.css';   // Import intro.js styles
import './Appp.css';

function Appp() {
  const [user, setUser] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const { postId } = useParams(); // Get postId from URL params
  // const [showIntro, setShowIntro] = useState(false); // Control tour visibility

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      // if (!user) {
      //   setShowIntro(true);
      // }
    });
    return () => unsubscribe();
  }, []);




  // useEffect(() => {
  //   if (showIntro) {
  //     // Define the intro tour steps
  //     const intro = introJs();
  //     intro.setOptions({
  //       steps: [
  //         {
  //           element: '.brand',
  //           intro: 'Welcome to TurnOnSell! Click here to return to the homepage at any time.',
  //           position: 'bottom',
  //         },
  //         {
  //           element: '.signin-link',
  //           intro: 'Click here to sign in or sign out of your account.',
  //           position: 'left',
  //         },
  //         {
  //           element: '.profile-link',
  //           intro: 'View and edit your profile here.',
  //           position: 'bottom',
  //         },
  //         {
  //           element: '.upload-link',
  //           intro: 'Post your textbooks for sale by clicking here!',
  //           position: 'bottom',
  //         },
  //       ],
  //       showBullets: true,
  //       exitOnOverlayClick: false,
  //       showProgress: true,
  //     });
  //     intro.start();
  //   }
  // }, [showIntro]);


  const signOutUser = () => {
    signOut(auth);
  };

  const handleUploadSuccess = () => {
    setUploadSuccess(true);
    setTimeout(() => setUploadSuccess(false), 3000); // Hide the success message after 3 seconds
  };

  return (
    <PostProvider postId={postId}> 
    <Router>
      <div className="App">
        {user ? (
          <>
            <Header user={user} signOutUser={signOutUser} />
            <div className="content">
              {uploadSuccess && <div className="success-message">Successfully uploaded!</div>}
            
              <Routes>
                <Route path="/" element={<PostList user={user} />} />
                <Route path="/search" element={<Search user={user} />} />
                <Route path="/profile" element={<ProfileView user={user} />} />
                <Route path="/upload" element={<UploadPost user={user} onUploadSuccess={handleUploadSuccess} />} />
                <Route path="/post/:id" element={<PostDetails />} />
                <Route path="/chat-history" element={<ChatHistory />} />
                <Route path="/chat/:postOwner/:chatId" element={<ChatPage />} />
                <Route path="/chat/:chatId" element={<ChatPage />} />
              </Routes>
             
            </div>
          </>
        ) : (
          <SignIn />
        )}
      </div>
    </Router>
    </PostProvider>
    
  );
}

function Header({ user, signOutUser }) {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const menuRef = useRef(null);

  useEffect(() => {
    if (user && user.uid) { // Ensure user and user.uid exist
      const q = query(
        collection(db, 'chats', user.uid, 'messages'),
        where('seen', '==', false)
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        setUnreadCount(snapshot.size);
      });

      return () => unsubscribe();
    }
  }, [user]);


  useEffect(()=>{
    const handleClickOutside=(event)=>{
      if(menuRef.current && !menuRef.current.contains(event.target)){
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown',handleClickOutside);
    
    return ()=>{
      document.removeEventListener('mousedown',handleClickOutside)
    }
  },[menuRef]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="App-header">
      <div className="brand" onClick={() => navigate('/')}>TurnOnSell</div>
      <div className="menu-button" onClick={toggleMenu}>
        &#9776;
      </div>
      <nav ref={menuRef} className={`header-buttons ${menuOpen ? 'open' : ''}`}>
        <button className="profile-link" onClick={() => navigate('/profile')}>Profile</button>
        <button className="search-link" onClick={() => navigate('/search')}>Search</button>
        <button className="upload-link" onClick={() => navigate('/upload')}>Upload Post</button>
        <button className="signin-link" onClick={signOutUser}>Sign Out</button>
        {/* <div className="message-icon-container" onClick={() => navigate('/chat-history')}>
          <ChatIcon style={{ fontSize: '2rem' }} />
          {unreadCount > 0 && <span className="message-notification">{unreadCount}</span>}
        </div> */}
      </nav>
    </header>
  );
}

export default Appp;

