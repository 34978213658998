// import firebase from "firebase";
// import "firebase/storage";
// import 'firebase/firestore';

// const firebaseApp = firebase.initializeApp({
  
//   apiKey: "AIzaSyCCxwi8H2VDLqrMaQrKa0iHJBjte182kNc",
//   authDomain: "react-search-app-f9112.firebaseapp.com",
//   projectId: "react-search-app-f9112",
//   storageBucket: "react-search-app-f9112.appspot.com",
//   messagingSenderId: "86477221134",
//   appId: "1:86477221134:web:bade6f09240737918cbb5f",
//   measurementId: "G-EGL4RX19LW"
 
// });



// const db = firebaseApp.firestore();
// const auth = firebase.auth();                                   // login /logout
// const storage = firebase.storage();                             // apload image like that

// // db.setting({timestmapInSnapshots:true})


// export { db, auth, storage };




 


// import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/firestore';

// const firebaseConfig = {
//   apiKey: "AIzaSyCCxwi8H2VDLqrMaQrKa0iHJBjte182kNc",
//   authDomain: "react-search-app-f9112.firebaseapp.com",
//   projectId: "react-search-app-f9112",
//   storageBucket: "react-search-app-f9112.appspot.com",
//   messagingSenderId: "86477221134",
//   appId: "1:86477221134:web:bade6f09240737918cbb5f",
//   measurementId: "G-EGL4RX19LW"
// };

// firebase.initializeApp(firebaseConfig);

// const auth = firebase.auth();
// const firestore = firebase.firestore();

// export { firebase, auth, firestore };



//-------------------------------------------------------below best code----------------------------------
// import { initializeApp } from 'firebase/app';
// import { getAuth } from 'firebase/auth';
// import { getFirestore } from 'firebase/firestore';
// import { getStorage } from 'firebase/storage';

// const firebaseConfig = {
//   apiKey: "AIzaSyCCxwi8H2VDLqrMaQrKa0iHJBjte182kNc",
//   authDomain: "react-search-app-f9112.firebaseapp.com",
//   projectId: "react-search-app-f9112",
//   storageBucket: "react-search-app-f9112.appspot.com",
//   messagingSenderId: "86477221134",
//   appId: "1:86477221134:web:bade6f09240737918cbb5f",
//   measurementId: "G-EGL4RX19LW"
// };

// const app = initializeApp(firebaseConfig);

// const db = getFirestore(app);
// const auth = getAuth(app);
// const storage = getStorage(app);

// export { db, auth, storage };

//-----------------------------------------------------------------------------------------



// import { initializeApp } from 'firebase/app';
// import { getFirestore } from 'firebase/firestore';
// import { getStorage } from 'firebase/storage';
// import { getAuth } from 'firebase/auth';

// const firebaseConfig = {
//   apiKey: "AIzaSyCCxwi8H2VDLqrMaQrKa0iHJBjte182kNc",
//   authDomain: "react-search-app-f9112.firebaseapp.com",
//   projectId: "react-search-app-f9112",
//   storageBucket: "react-search-app-f9112.appspot.com",
//   messagingSenderId: "86477221134",
//   appId: "1:86477221134:web:bade6f09240737918cbb5f",
//   measurementId: "G-EGL4RX19LW"


 
// };

// const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);
// const storage = getStorage(app);
// const auth = getAuth(app);

// export { db, storage, auth };

//-------------------------------------------------------------------------------------------------



// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCCxwi8H2VDLqrMaQrKa0iHJBjte182kNc",
  authDomain: "react-search-app-f9112.firebaseapp.com",
  projectId: "react-search-app-f9112",
  storageBucket: "react-search-app-f9112.appspot.com",
  messagingSenderId: "86477221134",
  appId: "1:86477221134:web:bade6f09240737918cbb5f",
  measurementId: "G-EGL4RX19LW"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { db, storage, auth };








//main -Project
// apiKey: "AIzaSyBD9HV1zW4Str8b4gvUYc06hyEOeC2aIeg",
// authDomain: "main-project-f15c5.firebaseapp.com",
// projectId: "main-project-f15c5",
// storageBucket: "main-project-f15c5.appspot.com",
// messagingSenderId: "898508689827",
// appId: "1:898508689827:web:f4f6ad324c466b4c160a16",
// measurementId: "G-TD9J4YZVDP"