 





//Best Code ************************************************************
// import React, { useEffect, useState } from 'react';
// import { collection, onSnapshot, query, deleteDoc, doc } from 'firebase/firestore';
// import { db } from './firebase';
// import Post from './Post';
// import './PostList.css';

// function PostList({ user }) {
//   const [posts, setPosts] = useState([]);

//   useEffect(() => {
//     const q = query(collection(db, 'posts'));
//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const postsData = snapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       setPosts(postsData);
//     });

//     return () => unsubscribe();
//   }, []);

//   const handleDelete = async (postId) => {
//     await deleteDoc(doc(db, 'posts', postId));
//   };

//   return (
//     <div className="post-list">
//       {posts.map((post) => (
//         <Post key={post.id} post={post} user={user} onDelete={() => handleDelete(post.id)} />
//       ))}
//     </div>
//   );
// }

// export default PostList;


//-----------------------------------------------------------------------------------------------------------------------------------------------
// import React, { useEffect, useState } from 'react';
// import { collection, onSnapshot, query, deleteDoc, doc } from 'firebase/firestore';
// import { db } from './firebase';
// import Post from './Post';
// import './PostList.css';
// import { Link } from 'react-router-dom';

// function PostList({ user, signIn }) {
//   const [posts, setPosts] = useState([]);

//   useEffect(() => {
//     const q = query(collection(db, 'posts'));
//     const unsubscribe = onSnapshot(q, (snapshot) => {
//       const postsData = snapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       setPosts(postsData);
//     });

//     return () => unsubscribe();
//   }, []);

//   const handleDelete = async (postId) => {
//     await deleteDoc(doc(db, 'posts', postId));
//   };

//   return (
//     <div className="post-list">
//       {/* <h2>Posts</h2> */}
//       {posts.map((post) => (
//         <Post key={post.id} post={post} user={user} onDelete={() => handleDelete(post.id)} />
//       ))}
//       {user ? (
//         <Link to="/upload" className="upload-link">Upload Post</Link>
//       ) : (
//         <p>Please <Link to="#" onClick={signIn}>sign in</Link> to upload a post.</p>
//       )}
//     </div>
//   );
// }

// export default PostList;







// import React, { useState, useEffect } from 'react';
// import { collection, getDocs } from 'firebase/firestore';
// import { db } from './firebaseConfig';
// import './PostList.css';

// function PostList() {
//   const [posts, setPosts] = useState([]);

//   useEffect(() => {
//     const fetchPosts = async () => {
//       const postsCollection = await getDocs(collection(db, 'posts'));
//       setPosts(postsCollection.docs.map(doc => ({ id: doc.id, ...doc.data() })));
//     };
//     fetchPosts();
//   }, []);

//   return (
//     <div className="post-list">
//       {posts.map(post => (
//         <div key={post.id} className="post">
//           <h3>{post.title}</h3>
//           <p>{post.content}</p>
//           {post.imageUrls && post.imageUrls.map((url, index) => (
//             <img key={index} src={url} alt="Post content" />
//           ))}
//           <p>Posted by: {post.username}</p>
//         </div>
//       ))}
//     </div>
//   );
// }

// export default PostList;






//----------------------------------------------------------------------------------------------------------

          // NEW CODE From different account
          //below
//----------------------------------------------------------------------------------------------------------




import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, query, deleteDoc, doc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import Post from './Post';
import './PostList.css';

function PostList({ user }) {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const q = query(collection(db, 'posts'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const postsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setPosts(postsData);
    });

    return () => unsubscribe();
  }, []);

  const handleDelete = async (postId) => {
    await deleteDoc(doc(db, 'posts', postId));
  };

  return (
    <div className="post-list">
      {posts.map((post) => (
        <Post key={post.id} post={post} user={user} onDelete={() => handleDelete(post.id)} />
      ))}
    </div>
  );
}

export default PostList;
